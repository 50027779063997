import { useUser } from '@folklore/auth';
import { useQuery, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import * as AppPropTypes from '../lib/PropTypes';

import { useApi } from './ApiContext';
import { useSite } from './SiteContext';

// import q95Sondage from '../assets/img/promotions/quatre95_sondage_2024.png';

export const PromotionsContext = React.createContext({});

export function usePromotionsQuery(opts = {}) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { handle: siteHandle } = useSite();
    const { data = null, ...queryResult } = useQuery({
        queryKey: ['promotions', siteHandle],
        queryFn: ({ queryKey: [, siteParam], signal }) =>
            api.promotions.get(
                {
                    site: siteParam,
                },
                null,
                null,
                { signal },
            ),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    const { data: items = null, ...metadata } = isArray(data) ? { data } : data || {};

    return data !== null
        ? {
              items,
              ...metadata,
              ...queryResult,
          }
        : queryResult;
}

export function usePromotions() {
    const context = useContext(PromotionsContext);
    return context;
}

const propTypes = {
    fixedPromotions: PropTypes.arrayOf(AppPropTypes.promotion),
    children: PropTypes.node,
};

const defaultProps = {
    fixedPromotions: [
        {
            id: 'consent',
            type: 'consent',
            placement: 'modal',
            force: true,
            trigger: {
                event: 'one',
                triggers: ['ready', 'pageview'],
                delay: 2000,
                consentGiven: null,
            },
        },
        // {
        //     id: 'subscribe_button',
        //     type: 'button',
        //     placement: 'notification',
        //     closeable: false,
        //     force: true,
        //     callToAction: {
        //         label: 'S’abonner',
        //         url: '/abonnement',
        //     },
        //     trigger: {
        //         event: 'one',
        //         triggers: ['ready', 'pageview'],
        //     },
        // },
        {
            id: 'subscribe_micromag',
            type: 'subscription',
            placement: 'modal',
            force: true,
            theme: 'full',
            subscription: 'micromag',
            title: 'Abonnez-vous<br/>pour continuer',
            description:
                'Vous allez recevoir gratuitement nos micromags en exclusivité chaque samedi.',
            titleLogin: 'Connectez-vous<br/>pour continuer',
            descriptionLogin: 'Vous aurez accès à tous les micromags en exclusivité.',
            trigger: {
                event: 'pageview',
                pageMatch: {
                    type: 'micromag',
                },
                count: 3,
                // not_subscribed: 'micromag',
            },
        },

        // {
        //     id: 'quatre95_sondage_2024',
        //     site: 'urbania',
        //     placement: 'notification',
        //     theme: 'quatre95',
        //     priority: 0,
        //     trigger: {
        //         event: 'pageview',
        //         pageMatch: {
        //             brands: ['quatre95'],
        //         },
        //         delay: 2000,
        //     },
        //     title: 'Courez la chance de gagner 100$',
        //     description:
        //         'en répondant à un sondage sur le contenu Quatre95. <strong>Ça ne vous prendra que quelques minutes, promis.</strong>',
        //     image: {
        //         url: q95Sondage,
        //     },
        //     url: 'https://cube.ca1.qualtrics.com/jfe/form/SV_d1kjCoUlLD7oNPU?Source=SW',

        //     callToAction: {
        //         type: 'button',
        //         label: 'Je participe',
        //         external: true,
        //     },
        // },

        // {
        //     id: 'mollogotchi',
        //     // type: 'subscription',
        //     placement: 'notification',
        //     force: true,
        //     // theme: 'full',
        //     // subscription: 'micromag',
        //     image: {
        //         url: '',
        //     },
        //     title: 'Mollogotchi',
        //     description: 'Essayez Mollogotchi',
        //     trigger: {
        //         event: 'one',
        //         triggers: [
        //             {

        //             }
        //         ]
        //     },
        // },
    ],
    children: null,
};

export function PromotionsProvider({ fixedPromotions, children }) {
    const { items: loadedPromotions = [] } = usePromotionsQuery();
    const { subscriptions: userSubscriptions = null } = useUser() || {};
    const promotions = useMemo(
        () => [...(fixedPromotions || []), ...(loadedPromotions || [])],
        [fixedPromotions, loadedPromotions],
    );
    const [donePromotions, setDonePromotions] = useState(
        (Cookies.get('promotions_done') || '').split(',').filter((it) => it !== ''),
    );
    const userSubscribed = useMemo(
        () =>
            (userSubscriptions || [])
                .filter(({ subscribed = false }) => subscribed)
                .map(({ id }) => id),
        [userSubscriptions],
    );
    const markAsDone = useCallback(
        (id) => {
            const newDonePromotions =
                donePromotions.indexOf(id) === -1 ? [...donePromotions, id] : donePromotions;
            setDonePromotions(newDonePromotions);
            Cookies.set('promotions_done', newDonePromotions.join(','), {
                expires: 365,
            });
        },
        [setDonePromotions, donePromotions],
    );
    const filteredPromotions = useMemo(
        () => [
            ...(promotions || [])
                .filter(
                    ({ id, subscription = null, force = false }) =>
                        (force || donePromotions.indexOf(id) === -1) &&
                        (subscription === null || userSubscribed.indexOf(subscription) === -1),
                )
                .sort(({ priority: aPriority = 0 }, { priority: bPriority = 0 }) => {
                    if (aPriority === bPriority) {
                        return 0;
                    }
                    return aPriority > bPriority ? 1 : -1;
                }),
        ],
        [fixedPromotions, promotions, donePromotions, userSubscribed],
    );
    const value = useMemo(
        () => ({
            promotions: filteredPromotions,
            allPromotions: promotions,
            markAsDone,
        }),
        [promotions, filteredPromotions, markAsDone],
    );

    return <PromotionsContext.Provider value={value}>{children}</PromotionsContext.Provider>;
}

PromotionsProvider.propTypes = propTypes;
PromotionsProvider.defaultProps = defaultProps;
